<template>
	<div>
		<div class="indexUser">
			<div>
				<!-- header  点击 -->
				<headers :userList="userList"></headers>
			</div>
			<div class="bg1 userBox">
				<div class="wrap main_content">
					<div class="fl my">
						<div class="myTxt bgw">
							<img v-if="!isDefalutImg" :src="userList.avatar" @error="isDefalutImg = !isDefalutImg" />
							<img v-else src="@/assets/image/Furtherconsultation_Defaulthead@2x.png" alt />
							<div class="fr txt">
								<div>
									用户名: <span> {{userList.user_nickname}}</span>
								</div>
								<div>
									出生日期:
									<span>{{userList.birthday}}</span>
								</div>
								<div>
									性别:
									<span v-if="userList.sex == 1">男</span>
									<span v-if="userList.sex == 2">女</span>
									<span v-if="userList.sex == 0">保密</span>
								</div>
								<div>
									实名认证:
									<span>{{realname}}</span>
								</div>
								<div>
									会员等级:
									<span>{{userList.user_level_txt}}</span>
								</div>
								<p>
									用户类型:
									<span>{{userList.is_daili==1?"代理商":"注册用户"}}</span>
								</p>
							</div>
						</div>
						<div class="order">
							<div class="fl">
								<div class="goodsOrder bgw">
									<h1>商品购买订单</h1>
									<button @click="GoAllOrder(-1)">查看全部订单</button>
									<div class="allOrder">
										<div @click="GoAllOrder(0)">
											{{ daifukuan }}
											<br />待付款
										</div>
										<div @click="GoAllOrder(1)">
											{{ daifahuo }}
											<br />待发货
										</div>
										<div @click="GoAllOrder(2)">
											{{ daishouhuo }}
											<br />待收货
										</div>
										<div @click="GoAllOrder(3)">
											{{ daipingjia }}
											<br />待评价
										</div>
										<div @click="GoAllOrder(4)">
											{{ daituihuo }}
											<br />退换货
										</div>
									</div>
								</div>
								<div class="integral bgw">
									<h1>积分兑换</h1>
									<p class="one" v-if="scoreShopList[0]">
										{{ scoreShopList[0].goods_name }}
										<i></i>
										{{ scoreShopList[0].score }}积分
									</p>
									<p class="one" v-else>暂无更多兑换商品</p>
									<span @click="exChangeGood">兑换</span>
									<span @click="$router.push('/user/integral?state=1')">更多</span>
								</div>
							</div>
							<div class="fr">
								<div class="discount bgw">
									<h1>平台优惠劵</h1>
									<div class="box">
										<div v-for="(v, i) in couponList" :key="i">
											<p class="one msg1">￥{{ parseInt(v.money) }}</p>
											<p class="one msg2">满{{ parseInt(v.limit) }}可用</p>
											<p></p>
											<p class="msg3" v-if="v.exist">已领取</p>
											<p class="msg3" @click="getCoupon(v)" v-else>立即领取</p>
										</div>
									</div>
									<p class="all" @click="$router.push('/user/allCoupon')">查看全部优惠劵</p>
								</div>
							</div>
						</div>
					</div>
					<div class="fr nav">
						<h1>更多功能</h1>
						<p @click="tab1()">
							<img src="@/assets/image/right_Addressmanagement@2x.png" alt />地址管理
						</p>
						<p @click="tab2()">
							<img src="@/assets/image/right_invoice@2x.png" alt />发票管理
						</p>
						<p @click="tab3()">
							<img src="@/assets/image/right_Settledin@2x.png" alt />申请入驻
						</p>
						<p @click="tab4()">
							<img src="@/assets/image/right_Customerservice@2x.png" alt />
							<a :href="`tencent://message/?uin=${this.sysconfs.kefu_qq}&Menu=yes&Service=300&sigT=42a1e5347953b64c5ff3980f8a6e644d4b31456cb0b6ac6b27663a3c4dd0f4aa14a543b1716f9d45`">联系客服</a>
						</p>
						<p @click="tab5()">
							<img src="@/assets/image/right_Coupon@2x.png" alt />优惠劵
						</p>
						<p @click="tab6()">
							<img src="../../../assets/image/Listofpeople_expert_icon@2x.png" alt />我的会员
						</p>
						<p @click="tab7()">
							<img src="@/assets/image/right_Functionalfeedback@2x.png" alt />功能反馈
						</p>
						<p @click="tab8()">
							<img src="@/assets/image/right_Commonproblem@2x.png" alt />常见问题
						</p>
						<p @click="$router.push({name:'我的分销'})" v-if="userList.is_daili==1">
							<img src="@/assets/image/dailis.png" alt />我的分销
						</p>
						<!-- <p @click="tab9()"><img src="@/assets/image/right_Addressmanagement@2x.png" alt="">分享</p> -->
					</div>
				</div>
			</div>
			<div>
				<publicBottom></publicBottom>
			</div>
		</div>
	</div>
</template>
<script>
	import headers from "./header"; // 头部 TOP 和header
	import publicBottom from "@/components/public/public_bottom"; //底部

	import {
		usergetinfo
	} from "@/request/user";
	import {
		score_shop
	} from "@/request/user"; // 可兑换商品列表
	import {
		buyScore
	} from "@/request/user"; // 兑换商品
	import {
		getAllCoupons
	} from "@/request/user";
	import {
		getMyOrder
	} from "@/request/user"; // 订单列表
	import {
		getSysconfs
	} from "@/request/public";
	import {
		getCoupons
	} from "@/request/user";
	export default {
		components: {
			headers,
			publicBottom
		},
		data() {
			return {
				userList: [], // 个人信息
				isDefalutImg: false, // 用户默认头像
				scoreShopList: [],
				couponList: [],
				daifukuan: 0,
				daifahuo: 0,
				daishouhuo: 0,
				daipingjia: 0,
				daituihuo: 0,
				sysconfs: {},
				realname:"",
			};
		},
		created() {
			let datas = localStorage.getItem("lzyyToken");
			this.shop_id = localStorage.getItem("lzyyShopId");
			let Athis = this;
			if (datas != null) {
				this.$tokens = datas;
			} else {
				this.$message.error("登录错误，请重新登录");
				setTimeout(function() {
					Athis.$router.replace({
						path: "/land"
					});
				}, 1000);
			}
			this.start();
			// this.state=this.$route.query.state
		},
		methods: {
			GoAllOrder(e) {
				this.$router.push({
					path: "/user/allOrder?state=" + e
				});
			},
			tab1() {
				// 地址
				this.$router.push({
					path: "/user/address"
				});
			},
			tab2() {
				//发票
				this.$router.push({
					path: "/user/invoice"
				});
			},
			tab3() {
				//申请入住
				this.$router.push({
					path: "/user/apply"
				});
			},
			tab4() {},
			tab5() {
				//优惠券
				this.$router.push({
					path: "/user/coupon"
				});
			},
			tab6() {
				//电子会员卡
				this.$router.push({
					path: "/user/member"
				});
			},
			tab7() {
				//功能反馈
				this.$router.push({
					path: "/user/feedBack"
				});
			},
			tab8() {
				//常见问题
				this.$router.push({
					path: "/user/problem"
				});
			},
			start() {
				this.$axios.post("/api/user/is_realauth",{
					token:this.$token
				}).then(res=>{
					if(res.data.code==1){
						this.realname=res.data.msg;
					}else{
						this.realname=res.data.msg;
					}
				})
				//   个人信息
				usergetinfo({
					token: this.$tokens
				}).then(res => {
					if (res.code == 1) {
						this.userList = res.data;
					}
				});

				score_shop({
					token: this.$token
				}).then(res => {
					this.scoreShopList = res.data.data;
				});

				// 获取所有优惠券
				getAllCoupons({
					token: this.$token,
					shop_id: this.shop_id
				}).then(res => {
					if (res.code == 1) {
						this.couponList = res.data;
						this.couponList = this.couponList.splice(0, 4)
					}
				});

				// 获取订单状态
				getMyOrder({
					status: "0",
					token: this.$token
				}).then(res => {
					if (res.code == 1) {
						this.daifukuan = res.data.total;
					}
				});
				getMyOrder({
					status: "1",
					token: this.$token
				}).then(res => {
					if (res.code == 1) {
						this.daifahuo = res.data.total;
					}
				});
				getMyOrder({
					status: "2",
					token: this.$token
				}).then(res => {
					if (res.code == 1) {
						this.daishouhuo = res.data.total;
					}
				});
				getMyOrder({
					status: "3",
					token: this.$token
				}).then(res => {
					if (res.code == 1) {
						this.daipingjia = res.data.total;
					}
				});

				getMyOrder({
					status: "6,7",
					token: this.$token
				}).then(res => {
					if (res.code == 1) {
						this.daituihuo = res.data.total;
					}
				});

				// 获取网站基本信息
				getSysconfs({
					token: this.$token
				}).then(res => {
					if (res.code == 1) {
						this.sysconfs = res.data;
					}
				});
			},
			exChangeGood() {
				buyScore({
					token: this.$token,
					shop_id: this.shop_id,
					id: this.scoreShopList[0].id
				}).then(res => {
					if (res.code == 1) {
						this.$message({
							type: "success",
							message: "兑换成功"
						});
					} else {
						this.$message.error(res.msg)
					}
				});
			},
			getCoupon(v) {
				getCoupons({
					token: this.$token,
					id: v.id
				}).then(res => {
					if (res.code == 1) {
						getAllCoupons({
							token: this.$token,
							shop_id: this.shop_id
						}).then(res => {
							if (res.code == 1) {
								this.couponList = res.data;
								this.couponList = this.couponList.splice(0, 4)
							}
						});
						this.$message.success("领取成功");
					} else {
						this.$message.error(res.msg);
					}
				});
			}
		}
	};
</script>

<style lang="less" scoped>
	.nav {
		width: 201px;
		height: 100%;
		background: white;
		padding-top: 26px;
		padding-left: 38px;
		font-size: 16px;

		h1 {
			font-size: 16px;
			margin-bottom: 30px;
		}

		p {
			cursor: Pointer;
			height: 50px;
			line-height: 50px;
		}

		img {
			vertical-align: middle;
			width: 32px;
			height: 32px;
			margin-right: 14px;
		}
	}

	.discount {
		width: 440px;
		height: 4146px;
		padding-left: 36px;
		padding-right: 36px;
		padding-top: 32px;

		.all {
			position: absolute;
			bottom: 27px;
			right: 23px;
			color: #4eeec8;
			font-size: 18px;
			cursor: Pointer;
		}

		h1 {
			font-size: 24px;
			font-weight: 400;
		}

		.box {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			>div {
				width: 168px;
				height: 116px;
				background: #4eeec8;
				margin-top: 32px;
				text-align: center;
				cursor: Pointer;
			}
		}

		.msg1 {
			font-size: 26px;
			color: white;
			margin-top: 12px;
		}

		.msg2 {
			font-size: 16px;
			color: white;
			margin-top: 2px;
		}

		.msg3 {
			width: 112px;
			height: 28px;
			line-height: 28px;
			background: rgba(255, 255, 255, 1);
			border-radius: 14px;
			color: #4eeec8;
			margin: auto;
			margin-top: 8px;
		}
	}

	.integral {
		// margin-top: 26px;
		padding-left: 36px;
		padding-right: 36px;
		padding-top: 32px;
		width: 508px;
		height: 153px;
		position: relative;

		h1 {
			font-size: 24px;
			font-weight: 400;
		}

		p {
			margin-top: 34px;
			width: 82%;
			color: #808080;
			font-size: 18px;
		}

		span:nth-of-type(1) {
			position: absolute;
			bottom: 32px;
			right: 32px;
			display: inline-block;
			width: 67px;
			height: 28px;
			background: rgba(78, 238, 200, 1);
			border-radius: 13px;
			font-size: 14px;
			text-align: center;
			font-weight: 400;
			color: rgba(255, 255, 255, 1);
			line-height: 28px;
			cursor: Pointer;
		}

		span:nth-of-type(2) {
			cursor: Pointer;
			position: absolute;
			top: 35px;
			right: 35px;
			color: #4eeec8;
		}

		.one i {
			margin-left: 10px;
		}
	}

	.goodsOrder {
		margin-bottom: 26px;
		padding-left: 36px;
		padding-right: 36px;
		padding-top: 32px;
		width: 508px;
		height: 269px;

		h1 {
			font-size: 24px;
			font-weight: 400;
		}

		button {
			width: 100%;
			height: 60px;
			font-size: 18px;
			background: #656565;
			margin-top: 36px;
			cursor: Pointer;
		}

		.allOrder {
			display: flex;
			margin-top: 24px;
			text-align: center;

			div {
				cursor: Pointer;
				width: 50%;
				height: 72px;
				border-left: 1px dashed #e6e6e6;
				color: #808080;
				line-height: 28px;
				padding-top: 8px;
			}

			div:nth-of-type(1) {
				border-left: none;
			}
		}
	}

	.order {
		margin-top: 26px;
	}

	.txt {
		width: 723px;
		text-align: left;
		font-size: 18px;

		>div {
			display: inline-block;
			margin-bottom: 20px;
		}

		p {
			margin-bottom: 30px;
		}

		span {
			color: #808080;
			margin-left: 10px;
			margin-right: 59px;
		}
	}

	.myTxt {
		height: 220px;
		padding-left: 54px;
		padding-top: 35px;

		>img {
			width: 150px;
			height: 150px;
			border-radius: 50%;
		}

		p {}
	}

	.my {
		width: 973px;
		height: 100%;
		position: relative;
	}

	.main_content {
		overflow: hidden;
		height: 694px;
	}

	.indexUse_content {
		background: #f5f5f5;
		padding-top: 24px;
		padding-bottom: 24px;
	}
</style>
